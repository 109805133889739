import React, { useEffect } from 'react';

function Sample() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqscript';
    script.defer = true;
    script.src = 'https://salesiq.zohopublic.com/widget';
    
    const zohoScript = document.createElement('script');
    zohoScript.type = 'text/javascript';
    zohoScript.id = 'zsiqchat';
    zohoScript.innerHTML = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "siqe480933030aa777d1d2d0f8bf7328ef77937b50c3caaa72b9299d0b1b18f6b0ff2ff2580de0b394375250c1c2d8628d9", 
        values: {},
        ready: function() {}
      };
    `;
    
    document.body.appendChild(script);
    document.body.appendChild(zohoScript);

    // Cleanup script on unmount
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(zohoScript);
    };
  }, []);

  return <div></div>;
}

export default Sample;
