import React from 'react'
import { motion } from 'framer-motion'
import './Capabilities.css'
import {safehands, money, moneybox, ontime} from '../../Assets/Images'
import { Link } from 'react-router-dom'



function Capabilities() {
  return (
    
    <div className='header1'>
      <div className='text-container'>
        <h1>Our Capabilities</h1>
        <p>Our strong skills are anything and everything where Digital transformation, Artificial Intelligence, Web Development, Machine Learning, Software Product Development, Software Product Development in Use</p>
      </div>
        <div className='satiimgtxt'>
          <div className='capaimg'>
            {/* <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={satisfation} alt='satisfaction'>
            </motion.img> */}

            <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={safehands} alt='satisfaction'>
            </motion.img>  

            <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={money} alt='satisfaction'>
            </motion.img>

            <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={moneybox} alt='satisfaction'>
            </motion.img>

            <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={ontime} alt='satisfaction'>
            </motion.img>

            {/* <motion.img animate={{rotateY:360}} 
            transition={{
              repeat:Infinity, duration:2
            }} src={commitment} alt='satisfaction'>
            </motion.img> */}
            
          </div>

          <div className='subtext'>
            {/* <Link to='/About'>
            <h1>Satisfaction Guarantee</h1>
            </Link> */}
            <Link style={{textDecoration: 'none'}} to='/aboutus'>
            <h1> You are with Safe Hands</h1>
            </Link>
            <Link style={{textDecoration: 'none'}} to='/aboutus'>
            <h1>Value For Money</h1>   
            </Link>
            <Link style={{textDecoration: 'none'}} to='/aboutus'>                   
            <h1>We work within your budget</h1> 
            </Link>
            <Link style={{textDecoration: 'none'}} to='/aboutus'>                   
            <h1>On Time Delivery</h1>
            </Link>
            {/* <Link to='/About'>
            <h1>100% Commitment-Free</h1>  
            </Link>              */}
        </div> 
       </div>
      </div>
    
  )
}

export default Capabilities