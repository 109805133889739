import React from 'react'
import './Adddigitrans.css'
import { Link } from 'react-router-dom'

function Adddigitrans() {
  return (
    <div>
    <div className='adddigitrans'>
        <h1>Add Digital Transformation to your existing Company</h1><br/><br/><br/><br/>
        <Link to='/contactus'>
        <button>Learn More</button>
        </Link>
    </div>
    </div>
  )
}

export default Adddigitrans