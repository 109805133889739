import React from 'react'
import  {Link} from 'react-router-dom'
import './Info.css'
import {Tech1, Tech2, Tech3, Tech4, Tech5} from '../../Assets/Images'

const Info = () => {
  
  return (
    <div className='infocontainer'>
      <div className='about'>
        <p>ABOUT TECHNACLE</p>
        <h2>Unleashing Disruptive Excellence</h2>
        <p>
          We were established in 2019 by a global team of professionals with decades of experience in delivering operational excellence to clients. Since then, we are growing consistently and evolved and expanded our capabilities to provide best-in-class services to our clients with our hybrid service model. Our on-shore consultants and engineers work with clients to determine the right solution while achieving great cost advantages with the prudent use of offshore delivery centers.
        </p>
        <Link to ="/aboutus">
        <button>Read More</button>
        </Link>
      </div>
      <div className='aboutimg'>
          <img className='timg1' src={Tech3} alt='tech1'></img>
          <img className='timg2' src={Tech2} alt='tech2'></img>
          <img className='timg3' src={Tech1} alt='tech3'></img>
          <img className='timg4' src={Tech4} alt='tech4'></img>
          <img className='timg5' src={Tech5} alt='tech5'></img>
        </div>

      </div>
  )
}

export default Info